import Util from "@/utils/utils";
import { getList, getExport, changeB2bActivityById } from "./api";
export default {
  name: "terminalInspectionRecord",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/activityConfigurationList",
          name: "促销活动",
          title: "促销活动"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "活动编码",
          prop: "activityCode",
          width: 250,
        },
        {
          label: "活动名称",
          prop: "activityName",
          width: 250,
        },
        {
          label: "促销方式",
          prop: "discountTypeName",
          width: 250,
        },
        {
          label: "开始时间",
          prop: "startDate",
          width: 250,
        },
        {
          label: "结束时间",
          prop: "endDate",
          width: 250,
        },
        {
          label: "是否暂停",
          prop: "pauseDesc",
          width: 250,
        },
        {
          label: "状态",
          prop: "statusDesc",
          width: 250,
        },
        // {
        //   label: "转单有效期",
        //   prop: "transferExpireDate",
        //   width: 250,
        // },
        {
          label: "创建日期",
          prop: "createDateDesc",
          width: 250,
        },
        // {
        //   label: '提交日期',
        //   prop: 'submitDate',
        //   width: 250,
        // },
        {
          label: "操作",
          slot: "btnClick",
          width: 150,
          fixed: 'right',

        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,

      tableLoading: false,
      selectedRowKeys: [],
      selectRow: []
    };
  },

  mounted() {
    this.tablelist();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    fun_date() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    },
    // 选择项更改
    onChange(selRows) {
      this.selectRow = selRows
      this.selectedRowKeys = selRows[0].id;

    },
    edit() {
      if (this.selectRow.length <= 0) {
        this.$message.warning('请选择一项数据进行操作')
        return
      }
      if (this.selectRow.length > 1) {
        this.$message.warning('只支持选择一项进行操作，请重新选择')
        return
      }
      if (this.selectRow[0].isdelay) {
        this.$message.warning('当前活动已过期无法进行编辑，请重新选择')
        return
      }
      if (this.selectRow[0].status == 0 || (this.selectRow[0].status == 1 && this.selectRow[0].pause == 'Y')) {
        let routeUrl = this.$router.resolve({
          path: "/activityConfigurationList/edit",
          query: {
            id: this.selectRow[0].id
          }
        });
        window.open(routeUrl.href, "_blank");
        return
      } else {
        this.$message.warning('该状态不能编辑，只有草稿状态和暂停状态才能编辑')
        return
      }


    },
    // 删除 修改 暂停操作
    opertion(type) {
      if (this.selectRow.length <= 0) {
        this.$message.warning('请选择一项数据进行操作')
        return
      }
      if (this.selectRow.length > 1) {
        this.$message.warning('只支持选择一项进行操作，请重新选择')
        return
      }
      if (this.selectRow[0].isdelay) {
        this.$message.warning('当前活动已过期无法进行该操作，请重新选择')
        return
      }
      if (type == 'Y') {
        if (this.selectRow[0].pause == 'Y') {
          this.$message.warning('当前活动已是暂停状态')
          return
        }
      }
      if (type == '2') {
        if (this.selectRow[0].status == 2) {
          this.$message.warning('当前活动已是已取消状态')
          return
        }
      }
      let data = {
        id: this.selectedRowKeys,
        type: type
      }
      changeB2bActivityById(data).then(res => {
        if (res.code == 0) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.warning(res.data.msg)
        }
        this.tablelist()
      })
    },
    // 新增
    add() {
      // 新增条页面
      let routeUrl = this.$router.resolve({
        path: "/activityConfigurationList/add",
      });
      window.open(routeUrl.href, "_blank");
    },

    // 删除
    delet() {

    },
    // 导出
    exportClick() {
      let data = {
        // pageNo: 1,
        // pageSize: 300000,
        ...this.ssearchList
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "三品提报.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },

    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.tablelist();
    },
    searchFun(arg) {
      this.pager.pageNo = 1
      this.ssearchList = {
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },
    // 获取列表数据
    tablelist() {
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        ...this.ssearchList
      }
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.code == 0) {
          if (res.data.list && res.data.list.length > 0) {
            this.tableData = res.data.list;
            this.searchNumber = res.data.totalRows;
            this.pager.count = res.data.totalRows;
            this.tableData.forEach(item => {
              item.isdelay = new Date(item.endDate).getTime() <= new Date().getTime()
            })
          } else {
            this.tableData = []
          }
          this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }
      });
    },
    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        path: "/activityConfigurationList/openView",
        query: { id: row.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    handleChange(e) {
    },

    onChangeEndTime(e, time) {
      this.ssearchList.createTime = time[0] + '_' + time[1];
    },
    searchClick() {
      this.tablelist();
    }
  }
};
